import React from 'react'
import PropTypes from 'prop-types'

const Section = ({ children }) => (
  <div className='p-10 mb:p-16 px-0 md:px-6'>
    {children}
  </div>
)

Section.propTypes = {
  children: PropTypes.node.isRequired
}

export default Section
