/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

import defaultOgImage from '../images/opengraph/facebook.jpg'

function Seo ({ description, lang, meta, title, image, urlPath }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            pagesData {
              seo {
                description {
                  it
                  en
                  de
                }
              }
            }
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.pagesData.seo.description[lang]
  const ogImage = image || defaultOgImage
  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: 'description',
          content: metaDescription
        },
        {
          property: 'og:title',
          content: title
        },
        {
          property: 'og:description',
          content: metaDescription
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          property: 'og:image',
          content: ogImage
        },
        {
          property: 'og:url',
          content: site.siteMetadata.siteUrl + urlPath
        }
      ].concat(meta)}
    />
  )
}

Seo.defaultProps = {
  lang: 'it',
  meta: [],
  description: ''
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired
}

export default Seo
